import { createContext } from "react";

import IClient from '../../interfaces/Client';

interface Guia {
  numeroGuiaTiss: string;
  numeroGuiaSADT: string;
  numeroSenhaSADT: string;
}

interface Atendimentos {
  atendimentoConsulta: string;
  atendimentoExame: string;
}

export interface DataForToken {
  id: string,
  cdITAgendaCentral: number | undefined,
  carteirinha: string,
  cpf: string,
  validade: string;
}

interface Params {
  priority: boolean;
  superIdoso: boolean,
  settings: boolean;
  client: IClient;
  guia: Guia;
  atendimentos: Atendimentos;
  companion: boolean;
  cpfPatient: string;
  cpfCompanion: string;
  dataForToken: DataForToken;
  filaGeneric: string;
  dynamicPriority: string,
  setFilaGeneric: (value: string) => void;
  setCpfPatient: (value: string) => void;
  setCpfCompanion: (value: string) => void
  setCompanion: (value: boolean) => void
  setPriority: (value: boolean) => void
  setSuperIdoso: (value: boolean) => void
  setSettings: (value: boolean) => void
  setClient: (value: IClient) => void
  setGuia: (value: Guia) => void
  setAtendimentos: (value: Atendimentos) => void
  setDataForToken: (value: DataForToken) => void;
  setDynamicPriority: (value: string) => void
}

const Context = createContext<Params>({
  priority: false,
  superIdoso: false,
  settings: false,
  client: { name: "", backgroundColor: "", logo: "", textColor: "", buttonColor: "", textTitle: "" },
  guia: { numeroGuiaTiss: "", numeroGuiaSADT: "", numeroSenhaSADT: "" },
  atendimentos: { atendimentoConsulta: "", atendimentoExame: "" },
  companion: false,
  cpfCompanion: "",
  cpfPatient: "",
  dataForToken: { id: "", carteirinha: "", cdITAgendaCentral: 0, cpf: "", validade: "" },
  filaGeneric: "",
  dynamicPriority: "",
  setFilaGeneric: (value: string) => { },
  setCpfPatient: (value: string) => { },
  setCpfCompanion: (value: string) => { },
  setCompanion: (value: boolean) => { },
  setPriority: (value: boolean) => { },
  setSuperIdoso: (value: boolean) => { },
  setSettings: (value: boolean) => { },
  setClient: (value: IClient) => { },
  setGuia: (value: Guia) => { },
  setAtendimentos: (value: Atendimentos) => { },
  setDataForToken: (value: DataForToken) => { },
  setDynamicPriority: (value: string) => { }
});

export default Context;
