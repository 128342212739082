import React, { useContext, useEffect, useState } from "react";
import { FaAccessibleIcon, FaUser } from "react-icons/fa";
import { useHistory } from 'react-router-dom'

import Button from "../UI/Button";
import Context from "../Context";

import Modal from "../NewModal";

import api from "../../services/api";

import "./styles.css";

interface DynamicPriorityProps {
  modalEnable: boolean;
  setModalEnable: (state: boolean) => void;
}

interface Priority {
  ID_PRIORIDADE: string;
  DS_PRIORIDADE: string;
}

const DynamicPriority: React.FC<DynamicPriorityProps> = (
  { modalEnable, setModalEnable },
) => {
  const history = useHistory();

  const [priorities, setPriorities] = useState<Priority[]>([]);
  const [prioritiesModal, setPrioritiesModal] = useState<boolean>(false);
  const { client, setDynamicPriority } = useContext(Context);

  const handleSelectPriority = (id: string) => {
    setModalEnable(true);
    setPrioritiesModal(false);
    setDynamicPriority(id);
    history.push("/check-in")
  };

  useEffect(() => {
    api.get<Priority[]>("/priorities").then(
      (response) => setPriorities(response.data),
      (err) => console.error(err),
    );
  }, []);

  return <div>
    <Modal enabled={modalEnable}>
      <Button
        onClick={() => {
          setModalEnable(true);
          setDynamicPriority("");
          history.push("/check-in")
        }}
        style={{
          backgroundColor: client.buttonColor || "#6c5ce7",
          color: "#FFF",
          fontWeight: 400,
          margin: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        Senha Comum <FaUser size={25} style={{ margin: 10 }} />
      </Button>
      <Button
        onClick={() => {
          setPrioritiesModal(true);
        }}
        style={{
          backgroundColor: client.buttonColor || "#6c5ce7",
          color: "#FFF",
          fontWeight: 400,
          margin: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        Senha Prioritaria <FaAccessibleIcon size={30} style={{ margin: 10 }} />
      </Button>

      <p style={{ fontWeight: 400, textAlign: "center" }}>
        Pessoas portadoras de deficiência, idosos, gestantes, lactantes, e as
        pessoas acompanhadas por crianças de colo de acordo com a Lei Federal
        10.048 de 08/11/2000.
      </p>
      <p style={{ fontWeight: 400, textAlign: "center" }}>
        Com a lei 13.466/2017, criou-se o "super idoso", idosos acima de 80
        anos, que em razão da idade, mais avançada em relação aos demais,
        necessitam de prioridades.
      </p>
    </Modal>
    <Modal enabled={prioritiesModal} >
      {priorities.map((priority) => {
        return <Button
          key={priority.ID_PRIORIDADE}
          onClick={() => {
            handleSelectPriority(priority.ID_PRIORIDADE);
          }}
          style={{
            backgroundColor: client.buttonColor || "#6c5ce7",
            color: "#FFF",
            fontWeight: 400,
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row"
          }}
        >
          {priority.DS_PRIORIDADE}
        </Button>;
      })}
      <h4 className="back" onClick={() => setPrioritiesModal(false)}>Voltar</h4>
    </Modal>
  </div>;
};

export default DynamicPriority;
