import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./styles.css";

interface Props {
  status: boolean;
  message?: string;
}

const Loading: React.FC<Props> = ({ status, message }) => {
  if (status) {
    return (
      <div id="loading" className="slide-in-fwd-center ">
        <CircularProgress
          variant="indeterminate"
          className="top"
          style={{
            color: "#6c5ce7",
            animationDuration: "1500ms",
          }}
          size={40}
          thickness={3}
        />
        <h2 className="fade-in">{message}</h2>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Loading;
