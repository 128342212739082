import React, { useContext, useEffect, useState } from "react";

import "./styles.css";

import Header from "../../components/Header";

import Context from "../../components/Context";
import Modal from "../../components/Modal";
import Cards from '../../components/Cards';

import DynamicPriority from "../../components/DynamicPriority";

const Home = () => {
  const { setPriority, setSuperIdoso, setSettings, client } = useContext(Context);
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  useEffect(() => {
    setModalOpened(false);
  }, [])

  return (
    <div id="page-home">
      <Header client={client} />
      <div id="page-home-outlet">
        <Cards />
      </div>
      {process.env.REACT_APP_DYNAMIC_PRIORITY === "true" ? <DynamicPriority modalEnable={!modalOpened} setModalEnable={setModalOpened} /> :
        modalOpened ? (
          ""
        ) : (
          <Modal
            status={modalOpened}
            setStatus={setModalOpened}
            cb={(p: boolean, superIdoso: boolean) => {
              setPriority(p);
              setSuperIdoso(superIdoso);
              setTimeout(() => {
                setSettings(true);
              }, 700);
            }}
          />
        )}
    </div>
  );
};
export default Home;
