import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import IClient from '../../interfaces/Client';
import { dateMaskPattern, hourMaskPattern } from '../../services/utils';
interface HeaderProps {
  client: IClient
}

const Header: React.FC<HeaderProps> = ({ client }) => {
  const [hour, setHour] = useState<Date>();
  const [, setSync] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setHour(new Date(Date.now()));
    setSync(setInterval(() => setHour(new Date(Date.now())), 1000));
  }, [])

  if (client.name === "INFINITY") {
    return (
      <div id="header" style={{ backgroundColor: "#262323" }}>
        <Link to="/home">
          <img src={client.logo} alt="Logomarca do Cliente" />
          <h1 style={{ color: client.textColor }}>Infinity</h1>
        </Link>
        <h1 className="product-name" style={{ color: client.textColor }}>{client.textTitle}</h1>
        {process.env.REACT_APP_CLOCK === "true" && hour ?
          <h2 className="hour" style={{ color: "#FFF" }}>{`${dateMaskPattern(hour)} ${hourMaskPattern(hour)}`}</h2>
          : ""}
      </div>
    );
  }

  return (
    <div id="header" style={{ backgroundColor: client.backgroundColor }}>
      <Link to="/home">
        <img src={client.logo} alt="Logomarca do Cliente" style={{ height: process.env.REACT_APP_CLIENT === "HRO" ? "40px" : undefined }} />
      </Link>

      <div className='header-right'>
        <h1 className="product-name" style={{ color: client.textColor }}>{client.textTitle}</h1>
        {process.env.REACT_APP_CLOCK === "true" && hour ?
          <h2 className="hour">{`${dateMaskPattern(hour)} ${hourMaskPattern(hour)}`}</h2>
          : ""}
      </div>
    </div>
  );

};

export default Header;
