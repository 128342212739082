import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Context, { DataForToken } from "./components/Context";
import Loading from "./components/Loading";
import IClient from "./interfaces/Client";
import Home from "./pages/Home";

import logo from "./assets/images/lampada_branca-8.png";
import hro_logo from "./assets/images/hro_logo.svg";
import santapaula_logo from "./assets/images/logo-santa_paula.png";
import h9j_logo from "./assets/images/h9j-logo-novo.jpeg";
import medimagem_logo from "./assets/images/logo_medimagem.png";

interface Guia {
  numeroGuiaTiss: string;
  numeroGuiaSADT: string;
  numeroSenhaSADT: string;
}

interface Atendimentos {
  atendimentoConsulta: string;
  atendimentoExame: string;
}

const CheckIn = lazy(() => import("./pages/CheckIn"));
const StandBy = lazy(() => import("./pages/StandBy"));

const TicketGeneric = lazy(() => import("./pages/TicketGeneric"));
const Liberation = lazy(() => import("./pages/Liberation"));
const ApiMode = lazy(() => import("./pages/ApiMode"));

const Routes = () => {
  const [priority, setPriority] = useState<boolean>(false);
  const [superIdoso, setSuperIdoso] = useState<boolean>(false);
  const [settings, setSettings] = useState<boolean>(false);
  const [dynamicPriority, setDynamicPriority] = useState<string>("");

  const [guia, setGuia] = useState<Guia>({
    numeroGuiaTiss: "",
    numeroGuiaSADT: "",
    numeroSenhaSADT: "",
  });
  const [atendimentos, setAtendimentos] = useState<Atendimentos>({
    atendimentoConsulta: "",
    atendimentoExame: "",
  });
  const [companion, setCompanion] = useState(false);
  const [cpfCompanion, setCpfCompanion] = useState("");
  const [cpfPatient, setCpfPatient] = useState("");
  const [filaGeneric, setFilaGeneric] = useState("");
  const [dataForToken, setDataForToken] = useState<DataForToken>({
    carteirinha: "",
    validade: "",
    cpf: "",
    cdITAgendaCentral: 0,
    id: "",
  });

  const clients = Array<IClient>();
  clients.push({
    name: "INFINITY",
    backgroundColor: "#FFF",
    logo: logo,
    textColor: "#FFF",
    buttonColor: "#262323",
    textTitle: "Selft Check-in",
  });
  clients.push({
    name: "HRO",
    backgroundColor: "#f2f2f2",
    logo: hro_logo,
    textColor: "#047782",
    buttonColor: "#282f38",
    textTitle: "Autoatendimento",
  });
  clients.push({
    name: "HSP",
    backgroundColor: "#FFF",
    logo: santapaula_logo,
    textColor: "#000",
    buttonColor: "#c1b136",
    textTitle: "Autoatendimento",
  });
  clients.push({
    name: "H9J",
    backgroundColor: "#FFF",
    logo: h9j_logo,
    textColor: "#000",
    buttonColor: "rgb(103, 190, 96)",
    textTitle: "Hospital Nove de Julho",
  });
  clients.push({
    name: "ATHENA",
    backgroundColor: "#FFF",
    logo: medimagem_logo,
    textColor: "#000",
    buttonColor: "#E40F18",
    textTitle: "Grupo Med Imagem",
  });
  const clientOn =
    clients.filter((item) => item.name === process.env.REACT_APP_CLIENT)[0];

  const [client, setClient] = useState<IClient>(clientOn);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Context.Provider
          value={{
            superIdoso,
            setSuperIdoso,
            companion,
            cpfCompanion,
            cpfPatient,
            setCpfPatient,
            setCompanion,
            setCpfCompanion,
            setPriority,
            settings,
            setSettings,
            client,
            setClient,
            guia,
            setGuia,
            atendimentos,
            priority,
            setAtendimentos,
            setDataForToken,
            dataForToken,
            filaGeneric,
            setFilaGeneric,
            dynamicPriority,
            setDynamicPriority
          }}
        >
          <Route path="/home" component={Home} />
          <Route
            path="/standby"
            component={() => (
              <Suspense fallback={<Loading status={true} />}>
                <StandBy />
              </Suspense>
            )}
          />
          <Route
            path="/api-mode"
            component={() => (
              <Suspense fallback={<Loading status={true} />}>
                <ApiMode />
              </Suspense>
            )}
          />
          <Route
            path="/check-in"
            component={() => (
              <Suspense fallback={<Loading status={true} />}>
                <CheckIn />
              </Suspense>
            )}
          />
          <Route
            path="/ticket-generic"
            component={() => (
              <Suspense fallback={<Loading status={true} />}>
                <TicketGeneric />
              </Suspense>
            )}
          />
          <Route
            path="/liberation"
            component={() => (
              <Suspense fallback={<Loading status={true} />}>
                <Liberation />
              </Suspense>
            )}
          />
        </Context.Provider>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
