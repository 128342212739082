import React, { useEffect } from "react";

import "./assets/styles/global.css";

import Routes from "./routes";

function App() {
  useEffect(() => {
    var link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    var linkApple = document.querySelector<HTMLLinkElement>("link[rel~='apple-touch-icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (!linkApple) {
      linkApple = document.createElement('link');
      linkApple.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(linkApple);
    }
    if (process.env.REACT_APP_CLIENT === "H9J") {
      link.href = 'https://www.h9j.com.br/Style%20Library/impar/img/favicon-h9j.png';
      linkApple.href = 'https://www.h9j.com.br/Style%20Library/impar/img/favicon-h9j.png';
    }
  }, [])
  
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
