import React, { CSSProperties, useContext } from "react";
import { useHistory } from "react-router-dom";

import Context from '../Context';

import "./styles.css";

interface CardProps {
  image?: string;
  title: string;
  description: string;
  rota: string;
  style?: CSSProperties,
  disabled?: boolean
}

const Card: React.FC<CardProps> = ({ image, title, description, rota, style, disabled }) => {
  const history = useHistory();
  const { client, setFilaGeneric } = useContext(Context);

  function navigateBy() {
    if (rota.includes("ticket-generic")) {
      if (title.includes("Infusão")) setFilaGeneric("27")
      if (title.includes("Exames Agendados")) setFilaGeneric("2")
      if (title.includes("Agendamento Consulta")) setFilaGeneric("4")
      if (!["Infusão", "Exames Agendados", "Consultas Agendadas", "Agendamento Consulta"].includes(title)) setFilaGeneric("-1")
    }
    if (!disabled) history.push(rota);
  }

  return (
    <div className={`card slide-in-fwd-center ${disabled ? "disabled" : ""}`} onClick={navigateBy} style={style}>
      <div className="card-preview" style={image ? {
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
      } : {}}>
      </div>
      <div className="card-infos" style={!disabled ? { backgroundColor: client.buttonColor } : { backgroundColor: "#CCC" }}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div >
  );
};

export default Card;
