import React from 'react';

import "./styles.css";

import Card from '../Card';

import qrMedImagem from '../../assets/images/menu/QR-MedImagem.svg';
import qr from "../../assets/images/qr.png";
import qrH9J from '../../assets/images/menu/consultaAgendada.svg';
import agendamento from '../../assets/images/menu/exameAgendada.svg';
import raioX from '../../assets/images/menu/raioXEletrocardiograma.svg';
import hemodialise from '../../assets/images/menu/hemodialise.svg';
import endoscopia from '../../assets/images/menu/endoscopia.svg';
import infusao from '../../assets/images/menu/infusao.svg';
import examLaboratorial from '../../assets/images/menu/examLaboratorial.svg';
import outros from '../../assets/images/menu/outros.svg';

const caseImageConsultas = () => {
    switch (process.env.REACT_APP_CLIENT) {
        case "H9J":
            return qrH9J
        case "ATHENA":
            return qrMedImagem
        default:
            return qr
    }
}

const Cards = () => {
    const cards = [];
    cards.push({
        id: 1,
        image: caseImageConsultas(),
        title: "Consultas agendadas",
        description: "",
        rota: "/check-in",
    });
    cards.push({
        id: 2,
        title: "Exames Agendados",
        description: "",
        rota: "/ticket-generic",
        image: agendamento,
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 7,
        image: outros,
        title: "Agendamento Consulta",
        description: "",
        rota: "/ticket-generic",
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 3,
        image: raioX,
        title: "Raio-X ou Eletrocardiograma",
        description: "",
        rota: "/ticket-generic",
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 4,
        image: hemodialise,
        title: "Hemodiálise",
        description:
            "",
        rota: "/ticket-generic",
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 5,
        image: endoscopia,
        title: "Endoscopia",
        description: "",
        rota: "/ticket-generic",
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 6,
        image: infusao,
        title: "Infusão",
        description: "",
        rota: "/ticket-generic",
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 8,
        image: examLaboratorial,
        title: "Exames Laboratoriais",
        description: "",
        rota: "/ticket-generic",
        disabled: process.env.REACT_APP_CLIENT === "ATHENA" ? true : false
    });
    cards.push({
        id: 9,
        image: qr,
        title: "Liberação",
        description: "",
        rota: "/liberation",
    });
    const featuresEnabled = String(process.env.REACT_APP_FEATURES).split(",");
    const featuresAvaliable = cards.filter(item => {
        if (featuresEnabled.includes(String(item.id))) return item;
        return undefined
    })

    return <div id="cards-navagation">
        <h3 id="tip">Selecione uma opção para iniciar seu atendimento</h3>
        <div className="cards-row">
            {featuresAvaliable.map((item, index) => {
                if (index < 4)
                    return (
                        <Card
                            key={index}
                            title={item.title}
                            description={item.description}
                            image={item.image}
                            rota={item.rota}
                            disabled={item.disabled}
                        />
                    );
                return null;
            })}
        </div>
        {featuresAvaliable.length > 4 ? <><div className="vertical-divider" />
            <div className="cards-row">
                {featuresAvaliable.map((item, index) => {
                    if (index >= 4)
                        return (
                            <Card
                                key={index}
                                title={item.title}
                                description={item.description}
                                image={item.image}
                                rota={item.rota}
                                disabled={item.disabled}
                            />
                        );
                    return null;
                })}
            </div></>
            : ""}
    </div>
}

export default Cards;