import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import "./styles.css";

interface NewModalProps {
    enabled: boolean,
    styles?: CSSProperties
}

const NewModal: React.FC<NewModalProps> = ({ enabled, children, styles }) => {
    const coverRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const [started, setStarted] = useState(false);

    const handleClose = useCallback(() => {
        if (coverRef.current) {
            coverRef.current.style.display = "flex";
            coverRef.current.classList.add("op-out");
        }

        if (modalRef.current) {
            modalRef.current.style.display = "flex";
            modalRef.current.classList.add("out");
        }

        setTimeout(() => {
            if (modalRef.current) {
                modalRef.current.style.display = "none";
                modalRef.current.classList.remove("out");
            }
            if (coverRef.current) {
                coverRef.current.style.display = "none";
                coverRef.current.classList.remove("op-out");
            }
        }, 700);

    }, []);

    useEffect(() => {
        if (!started) {
            if (enabled) setStarted(true);
        }
    }, [started, enabled])

    useEffect(() => {
        if (!enabled && started) {
            handleClose();
        }
    }, [enabled, started, handleClose])

    useEffect(() => {
        if (!enabled) return
        if (!started) return

        if (coverRef.current) {
            coverRef.current.style.display = "flex";
            coverRef.current.classList.add("op-in");
        }

        if (modalRef.current) {
            modalRef.current.style.display = "flex";
            modalRef.current.classList.add("in");
        }

        setTimeout(() => {
            if (modalRef.current) modalRef.current.classList.remove("in");
            if (coverRef.current) coverRef.current.classList.remove("op-in");
        }, 700);
    }, [enabled, started]);

    return <div id="Cover" ref={coverRef}>
        <div id="Modal" ref={modalRef} style={styles}>
            {children}
        </div>
    </div>
}

export default NewModal;