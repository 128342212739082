import React, { CSSProperties, useContext } from "react";
import Context from '../../Context';
import "./styles.css";

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: (event: any) => void;
  style?: CSSProperties;
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({ type, onClick, style, disabled, children }) => {
  const { client } = useContext(Context);

  return (
    <button onClick={onClick} type={type} className="button-component" disabled={disabled}
    style={{...style, backgroundColor: disabled ? "#CCC" : client.buttonColor, color: client.backgroundColor }}>
      {children}
    </button>
  );
};

export default Button;
